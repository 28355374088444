import React from "react";
import { Card, Text, IconButton } from "theme-ui";
import { FaPhone, FaEnvelope, FaLocationArrow } from "react-icons/fa";
import Section from "@components/Section";
import useSiteMetadata from "@helpers/useSiteMetadata";
import Navigation from "@components/Navigation";
import attachSocialIcons from "@helpers/attachSocialIcons";
import Divider from "@components/Divider";

const styles = {
  social: {
    mb: [3, 0],
    marginTop: 2
  }
};

const ContactInfo = () => {
  const { phone, address, email, social } = useSiteMetadata();

  return (
    <Section aside>
      <Card variant="paper">
        <Text variant="p">We'd love your feedback!</Text>
        {phone && (
          <Text>
            <IconButton variant="simple" role="presentation">
              <FaPhone />
            </IconButton>
            {phone}
          </Text>
        )}
        {email && (
          <Text>
            <IconButton variant="simple" role="presentation">
              <FaEnvelope />
            </IconButton>
            {email}
          </Text>
        )}
        {address && (
          <Text>
            <IconButton variant="simple" role="presentation">
              <FaLocationArrow />
            </IconButton>
            {address}
          </Text>
        )}
      </Card>
      <Card variant="paper" style={{ marginTop: 10 }}>
        <Text variant="h5">Social Media</Text>
        <Navigation
          items={attachSocialIcons(social)}
          variant={[`horizontal`, `vertical`]}
          wrapperStyle={styles.social}
        />
      </Card>
    </Section>
  );
};

export default ContactInfo;
