import React, { useContext } from "react";
import { Stack, Main, Sidebar } from "@layout";
import Divider from "@components/Divider";
import Seo from "@widgets/Seo";
import { Button, Card } from "theme-ui";
import ContactInfo from "@widgets/ContactInfo";
import LoginCard from "@widgets/LoginCard";
import { navigate } from "gatsby";
import { logout, AuthContext } from "@elegantstack/core-blog/src/context/auth";

export default ({ ...props }) => {
  const { pageContext: { services = {} } = {} } = props;
  const { user } = useContext(AuthContext);
  return (
    <>
      <Seo title="Join now" />
      <Divider />
      <Stack>
        <Main>
          <Card variant="paper" style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                logout();
                navigate("/");
              }}
              style={{ backgroundColor: "#ff7675", borderColor: "#ff7675" }}
            >
              Log out
            </Button>
          </Card>
        </Main>
        <Sidebar>
          <ContactInfo />
        </Sidebar>
      </Stack>
    </>
  );
};
